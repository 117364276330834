import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@design-system/typography';
import useScript from 'hooks/useScript';

import { Container } from 'layout';
import { Layout } from 'layout/Layout';
import ContentCardsList from 'components/ContentCardsList';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import { TOrderPageData } from './models';

import './OrderPage.scss';

/**
 * Private template
 */
export const OrderPage: FC<TOrderPageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
    redirects,
  },
  pageContext,
  location,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo, content_cards, form_title, link } = pageData;

  const scriptAdress = link ? `${new URL(link).origin}/promoForm.js` : '';
  useScript(scriptAdress);

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerAccount={headerAccount}
      color="blue"
      headerMode="transparent"
      className="order-page"
      redirects={redirects}
      location={location.pathname}
      privatePage
      data-testid="OrderPage"
    >
      <Container>
        <div className="order-page__banner">
          <ContentCardsList data={content_cards} />
          <div className="order-page__banner--right">
            <Typography
              variant="bodyXL"
              content={form_title}
              tag="h3"
              className="order-page__banner__title"
            />
            <iframe
              className="order-page__banner__form"
              src={link}
              title={form_title}
              id="phx-promo-form"
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishOrderTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      content_cards {
        ...ContentCardFragment
      }
      form_title
      link
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  OrderPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_order_template`
);
